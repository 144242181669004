import { autoinject, computedFrom, observable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BaseRoute } from 'base-route';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { ClientEAgreementCount, EAgreement, EAgreementReportInternalRequest, MyHttpApi, PrivilegeItem } from 'utils/api';
import { getSelectedCorporation } from 'utils/corporation-util';
import { EventuallyCorrectSearch } from 'utils/eventually-correct-search';


@autoinject
export class AdminReportEAgreementReport extends BaseRoute {
  @observable({ changeHandler: "search" })
  private startDate?: Date;
  @observable({ changeHandler: "search" })
  private endDate?: Date;
  @observable({ changeHandler: "search" })
  private clientId?: number;
  @observable({ changeHandler: "search" })
  private businessGroupId?: number;
  @observable({ changeHandler: "search" })
  private corporationId?: number;
  @observable({ changeHandler: "search" })
  private eAgreementId?: number;

  private ecs = new EventuallyCorrectSearch(
    // Lambda for passing our search params
    () => this.buildQueryParams(),
    // Lambda for searching. Passing plain function will destroy our this. and e.g. this.doPut will not work
    args => this.api.eAgreementReport(args),
    // Lambda for setting data once we are done.
    data => this.applyData(data),
  );

  private data: ClientEAgreementCount[] = [];

  private fieldList: FieldSpec[] = [];
  private eAgreementList: EAgreement[] = [];
  private privilege?: PrivilegeItem;

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N) {
    super();
  }

  override async activate() {
    this.privilege = getSelectedCorporation();
    const [eAgreementList, businessGroupList, clientList] = await Promise.all([
      this.api.eAgreementListAllActive(),
      this.api.businessGroupList(),
      this.api.clientList(),
    ]);
    this.eAgreementList = eAgreementList;
    this.fieldList = [
      { key: "clientId", header: "unit.unit", type: "lookup", lookupData: MyHttpApi.toHash(clientList), lookupKey: "nickname"
       },
      { key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: MyHttpApi.toHash(businessGroupList), lookupKey: "name" },
      { key: "eAgreementId", header: "app.eAgreement", type: "lookup", lookupData: MyHttpApi.toHash(eAgreementList), lookupKey: "name" },
      { key: "period", header: "common.month", type: "text", },
      { key: "count", header: "common.amount", type: "number", }
    ];
    await this.search();
  }

  protected override routeParams() {
    return {
      "clientId": Number,
      "businessGroupId": Number,
      "corporationId": Number,
      "eAgreementId": Number,
      "startDate": Date,
      "endDate": Date,
    };
  }

  buildQueryParams(): EAgreementReportInternalRequest {
    return {
      clientId: this.clientId,
      businessGroupId: this.businessGroupId,
      corporationId: this.corporationId,
      eAgreementId: this.eAgreementId,
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  @computedFrom("privilege.type", "privilege.id")
  get eAgreementListDynamic() {
    if (this.privilege?.type == "BUSINESS_GROUP") {
      return this.eAgreementList.filter(x => x.businessGroupId == this.privilege?.id);
    } else if (this.privilege?.type === "CLIENT") {
      return this.eAgreementList.filter(x => x.clientId == this.privilege?.id);
    }
    return this.eAgreementList;
  }

  async search() {
    await Promise.all([
      this.ecs.search(),
    ]);
  }

  applyData(data: ClientEAgreementCount[]) {
    this.data = data;
    super.rewriteWindowUrl(this.buildQueryParams());
  }

  @computedFrom("privilege.id")
  get searchListener() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (this.privilege?.id) {
      if (this.privilege.type == 'CLIENT') {
        this.clientId = this.privilege.id;
        this.businessGroupId = this.corporationId = undefined;
      }else if (this.privilege.type == 'BUSINESS_GROUP') {
        this.businessGroupId = this.privilege.id;
        this.clientId = this.corporationId = undefined;
      } else if (this.privilege.type == 'CORPORATION') {
        this.corporationId = this.privilege.id;
        this.clientId = this.businessGroupId = undefined;
      }
    } else {
      this.clientId = this.businessGroupId = this.corporationId = undefined;
    }
    return "";
  }
}
