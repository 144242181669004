import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { ActorListResponse, MyHttpApi, Server, ServerLog } from 'utils/api';

@autoinject
export class AdminServersEdit {
  private new = false;
  private server: Server = {
    id: "",
    acquired: new Date(),
    secureBoot: true,
    serialNumber: "",
    serverLocation: "BEL",
    serverType: "BELP",
    notes: "",
    retiringReason: "",
  };
  private serverLog: ServerLog[] = [];
  private logFieldList: FieldSpec[] = [];

  constructor(private readonly router: Router, private readonly api: MyHttpApi, private readonly i18n: I18N) {
  }

  async activate(params: { id: string }) {
    let [clientList, deletedClientList, actors] = await Promise.all([
      this.api.clientList(),
      this.api.clientListDeleted(),
      this.api.actorAdminList(),
    ]);

    let actorLookup: { [key: number]: ActorListResponse } = {};

    for (let a of actors) {
      actorLookup[a.id] = a;
    }

    this.logFieldList = [
      { key: "eventTime", header: "common.time", type: "date", format: "DD.MM.YYYY HH:MM", },
      { key: "clientId", header: "servers.client", type: "lookup", lookupData: MyHttpApi.toHash([...clientList, ...deletedClientList]), lookupKey: "nickname" },
      { key: "description", header: "common.desc", type: "text" },
      { key: "actorId", header: "login.username", type: "lookup", lookupData: actorLookup, lookupKey: "username" },
    ];

    if (params.id) {
      this.new = false;
      [this.server, this.serverLog] = await Promise.all([
        this.api.serverById({ id: params.id }),
        this.api.serverLogById({ id: params.id }),
      ]);
    } else {
      this.new = true;
    }
  }

  async save() {
    await this.api.serverUpdate(this.server);
    this.router.navigateBack();
  }
}
