import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { MyHttpApi, NewsType, NewsUpdateRequest } from 'utils/api';

@autoinject
export class AdminNewsEdit {
  private news: NewsUpdateRequest = {
    topicFi: "",
    longBodyFi: "",
    shortBodyFi: "",
    topicEn: "",
    longBodyEn: "",
    shortBodyEn: "",
    published: false,
    showAlsoAtPos: false,
    isServiceBreak: false,
    newsType: "NEWS", // * only used when creating a new one
    delete: false,
  };

  constructor(private readonly router: Router, private readonly api: MyHttpApi) {
  }

  async activate(params: { id?: string, type: NewsType; }) {
    this.news.newsType = params.type;
    if (params.id) {
      let tmp = await this.api.newsById({ id: parseInt(params.id), asUri: true });
      this.news = { ...tmp, delete: !!tmp.deleteTime };
    }
  }

  async save(deleted: boolean) {
    await this.api.newsUpdate({ ...this.news, delete: deleted });
    this.router.navigateBack();
  }
}
